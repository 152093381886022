@import './shared/styles/_vuetify-overrides.scss';

































































.cassie-pab-tooltip-icon-margin {
	margin-left: 2px;
}
